<template>
  <div class="mobi-page">
    <div class="login-main">
      <div class="header-item">
        <h3>登录</h3>
        <p>请使用验证码登录</p>
      </div>
      <ul class="form">
        <li
          class="mobile-item border-bottom"
          :class="mobileActive ? 'active' : ''"
        >
          <input
            class="mobile-input"
            type="text"
            pattern="[0-9]*"
            maxlength="11"
            placeholder="输入手机号"
            v-model.trim="mobile"
            @focus="mobileFocus"
            @blur="mobileBlur"
            @input="phoneInput"
          >
          <b
            class="clear-btn"
            :class="this.mobile.length > 0 ? 'active' : ''"
            @click="clearMobile"
          >
          </b>
        </li>
        <li
          class="code-item border-bottom"
          :class="codeInputActive ? 'active' : ''"
        >
          <input
            class="code-input"
            type="text"
            pattern="[0-9]*"
            maxlength="4"
            placeholder="输入验证码"
            v-model.trim="verify_code"
            @focus="codeFocus"
            @blur="codeBlur"
            @input="codeInput"
          >
          <b
            class="code-btn"
            :class="codeBtnActive ? 'active' : ''"
            @click="codeBtnTap">
            {{ codeBtnText }}
          </b>
        </li>
      </ul>
    </div>

    <div
      class="form-btn"
      :class="mobile.length === 11 && verify_code.length === 4 ? 'active' : ''"
      @click="login">
      登录
    </div>

    <div class="explain">
      <p>应国家对食品安全管控要求及</p>
      <p>食品溯源链条的完整，<s>首次登录需要验证</s></p>
    </div>

  </div>
</template>

<script>
import { openDB, saveData } from '../../utils/index-db'
import { Config } from '../../utils/config'
import { Toast } from 'vant'
import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'Login',
  data () {
    return {
      wholesalerId: '',

      mobile: '',
      mobileActive: false,
      verify_code: '',

      codeInputActive: false,
      codeBtnActive: false,
      codeBtnText: '获取验证码',

      flag: false // 是否可以发送验证码
    }
  },
  mounted () {
    openDB()
    this.wholesalerId = JSON.parse(localStorage.getItem('wholesaler_id'))
  },
  methods: {
    // 手机输入框聚焦
    mobileFocus () {
      this.mobileActive = true
    },
    // 手机输入框失焦
    mobileBlur () {
      this.mobileActive = false
    },
    // 手机输入框输入改变
    phoneInput () {
      if (this.mobile.length === 11 && (/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/.test(this.mobile))) {
        this.codeBtnActive = true
        this.flag = true
      } else {
        this.codeBtnActive = false
        this.flag = false
      }
    },
    // 清除手机号码
    clearMobile () {
      this.mobile = ''
    },

    // 验证码输入框聚焦
    codeFocus () {
      this.codeInputActive = true
    },
    // 验证码输入框失焦
    codeBlur () {
      this.codeInputActive = false
    },
    codeInput () {},
    // 点击获取验证码
    codeBtnTap () {
      if (this.flag) {
        this.$api.code.verify({
          client_id: Config.clientID,
          mobile: this.mobile
        }).then(res => {
          // 测试环境，自动填充验证码
          if (Config.baseApiUrl.indexOf('testing') !== -1 || Config.baseApiUrl.indexOf('demo') !== -1) {
            this.verify_code = res.data.result.verify_code
          }
          if (res.data.success) {
            Toast('验证码发送成功')
            let i = 60
            this.flag = false
            this.codeBtnText = i + 's'
            let time = setInterval(() => {
              i--
              this.codeBtnText = i + 's'
              if (i === 0) {
                this.codeBtnText = '获取验证码'
                this.flag = true
                clearInterval(time)
              }
            }, 1000)
          } else {
            Toast('验证码发送失败')
          }
        })
      }
    },

    // 用户登录
    login () {
      if (this.mobile.length === 11 && this.verify_code.length === 4) {
        Toast.loading({
          message: '正在登录...',
          forbidClick: true,
          duration: 0
        })

        this.$api.users.login({
          client_id: Config.clientID,
          mobile: this.mobile,
          verify_code: this.verify_code
        }).then(res => {
          if (res.data.success) {
            let token = res.data.result.token_type + ' ' + res.data.result.access_token
            localStorage.setItem('token', JSON.stringify(token))
            this.getUserInfo(token)
          } else {
            Toast('登录失败')
          }
        })
      }
    },
    // 获取用户信息
    getUserInfo (token) {
      axios({
        method: 'get',
        url: `${ Config.baseApiUrl }/v1/users1/profiles/me`,
        headers: {'Authorization': token}
      }).then(res => {
        if (res.data.success) {
          // 保存用户信息
          let userInfo = res.data.result
          let userProfile = {}
          let n = _.findIndex(userInfo.profiles, item => item.type === 1)
          n >= 0
            ? userProfile = userInfo.profiles[n]
            : userProfile = userInfo.profiles[0]
          userProfile.mobile = userInfo.mobile
          saveData(userProfile)
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
